import { AccessTierLevel } from '../enums/access-tier-level';

/** App user model. */
export class AccessTier {
	/** Tier. */
	public readonly tier: AccessTierLevel;

	/** Value until tier valid. */
	public readonly validUntil: Date;

	/** Is active. */
	public readonly isActive?: boolean;

	public constructor(data: AccessTierConstructorData) {
		this.tier = data.tier;
		this.validUntil = data.validUntil;
		this.isActive = data.isActive;
	}
}

type AccessTierConstructorData = AccessTier;
