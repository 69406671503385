import { Injectable, inject } from '@angular/core';

import { ProfileAppUserData, UserProfile, UserProfileEdit } from '../../models/user-profile';

import { AccessTierMapper } from './access-tier.mapper';
import { UserProfileDto, ProfileAppUserDataDto, UserProfileEditDto } from './dto/user-profile-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';

/** User profile mapper. */
@Injectable({ providedIn: 'root' })
export class UserProfileMapper
implements
    IMapperFromDto<UserProfileDto, UserProfile>,
    IMapperToDto<UserProfileEditDto, UserProfileEdit> {
	private readonly accessTierMapper = inject(AccessTierMapper);

	/** @inheritdoc */
	public toDto(data: UserProfileEdit): UserProfileEditDto {
		return {
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.email,
			phone: data.phone,
		};
	}

	/** @inheritdoc */
	public fromDto(dto: UserProfileDto): UserProfile {
		return new UserProfile({
			id: dto.id,
			userType: dto.user_type,
			email: dto.email,
			firstName: dto.first_name,
			lastName: dto.last_name,
			isActive: dto.is_active,
			appUserData: dto.app_user_data ? this.userDataMapper(dto.app_user_data) : undefined,
		});
	}

	/**
	 * Mapper for additional user data.
	 *
	 * @param dto App user dto data.
	 */
	private userDataMapper(dto: ProfileAppUserDataDto): ProfileAppUserData {
		return {
			phone: dto.phone,
			accessTier: dto.access_tier ? this.accessTierMapper.fromDto(dto.access_tier) : undefined,
			isCompanyOwner: dto.is_company_owner,
			company: dto.company,
			companyData: dto.company_data ?
				{
					id: dto.company_data.id,
					name: dto.company_data.name,
					accessTier: this.accessTierMapper.fromDto(dto.company_data.access_tier),
					owner: dto.company_data.owner,
					city: dto.company_data.city,
					address: dto.company_data.address,
					zipcode: dto.company_data.zipcode,
				} :
				undefined,
			currentAccessTier: dto.current_access_tier_info ?
				this.accessTierMapper.fromDto(dto.current_access_tier_info) :
				undefined,
		};
	}
}
