<input
	type="text"
	[formControl]="control"
	[matAutocomplete]="auto"
	[placeholder]="placeholder"
/>
<mat-autocomplete
	#auto="matAutocomplete"
	[displayWith]="readable"
>
	@if (loading) {
		<mat-progress-bar mode="indeterminate" />
	}
	@for (option of optionsList$ | async; track trackBy($index, option)) {
		<mat-option [value]="option">
			{{ readable(option) }}
		</mat-option>
	}
</mat-autocomplete>
