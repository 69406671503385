import { enumToArray } from '../utils/enum-to-array';

/** Level of access tiers. */
export enum AccessTierLevel {
	Tier1 = 1,
	Tier2 = 2,
	Tier3 = 3,
}

export namespace AccessTierLevel {
	const TITLE_MAP: Record<AccessTierLevel, string> = {
		[AccessTierLevel.Tier1]: 'Tier 1',
		[AccessTierLevel.Tier2]: 'Tier 2',
		[AccessTierLevel.Tier3]: 'Tier 3',
	};

	/**
	 * Convert a certain enum value to readable title.
	 * @param value Value of enum.
	 */
	export function toReadable(value?: AccessTierLevel | null): string {
		if (!value) {
			return '';
		}

		const readable = TITLE_MAP[value];
		if (readable === undefined) {
			console.warn(`Unknown AccessTierLevel: ${value}`);
		}
		return readable;
	}

	/**
	 * Get list of all enum items.
	 */
	export function toArray(): AccessTierLevel[] {
		return enumToArray(AccessTierLevel);
	}
}
