/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl } from '@angular/forms';

/**
 * Type for invalid callback.
 */
type InvalidCallbackFunction = (control: AbstractControl) => unknown;

/**
 * Method decorator for components that protect "form submit" function
 * Decorator only applied for methods that accepts first argument is form
 * instance.
 *
 * Also this Decorator accept an callback, that will be called if form is invalid.
 * Form instance will be passed as first argument to callback function.
 *.
 * Example:
 *  ```
 *  public submitForm(form: FormGroup): void {
 *    form.markAllAsTouched();
 *    if (form.invalid) {
 *      // Do some logic with errors
 *      return;
 *    }.
 *    // Do submit logic
 *  }.
 *  ```
 * This code is equal to:
 *
 *  ```
 *  @OnlyValidForm(this.handleInvalid)
 *  public submitForm(form: FormGroup): void {
 *     // Do submit logic
 *  }
 *  ```
 * .
 * @param invalidCallback Function to handle invalid form.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function OnlyValidForm(invalidCallback?: InvalidCallbackFunction): MethodDecorator {
	/**
	 * Handle function.
	 * @param _target Target of validation.
	 * @param _key Key.
	 * @param descriptor Descriptor.
	 */
	function wrapper(
		_target: Object,
		_key: string | symbol,
		descriptor: TypedPropertyDescriptor<any>,
	): TypedPropertyDescriptor<any> {
		const originalMethod = descriptor.value;
		descriptor.value = function(control: AbstractControl, ...args: any[]): any {
			control.markAllAsTouched();
			if (control.valid) {
				return originalMethod.apply(this, [control, ...args]);
			}

			if (invalidCallback) {
				invalidCallback(control);
			}
		};
		return descriptor;
	}

	return wrapper;
}
