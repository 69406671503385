/**
 * Validation error code.
 */
export enum ValidationErrorCode {

	/**
	 * Wrong email.
	 */
	Email = 'email',

	/**
	 * Required field.
	 */
	Required = 'required',

	/**
	 * Match of values error. When value of one control does not match to another.
	 */
	Match = 'match',

	/**
	 * Minimal length restriction.
	 */
	MinLength = 'minlength',

	/**
	 * Maximal length restriction.
	 */
	MaxLength = 'maxlength',

	/**
	 * Maximum value restriction.
	 */
	Min = 'min',

	/**
	 * Minimum value restriction.
	 */
	Max = 'max',

	/**
	 * Pattern restriction.
	 */
	Pattern = 'pattern',

	/**
	 * Jus law error.
	 */
	AppError = 'appError',

	/**
	 * Value is greater than the compared one.
	 */
	Greater = 'greater',

	/**
	 *  Value doesn't satisfy mask.
	 */
	Mask = 'mask',

	/**
	 * Value should be unique.
	 */
	UniqueValue = 'uniqueValue',

	/**
	 * Value required for Quill editor.
	 */
	QuillRequired = 'requiredError',

	/** Angular Material datepicker min date. */
	MatDatepickerMin = 'matDatepickerMin',

	/** Angular Material datepicker max date. */
	MatDatepickerMax = 'matDatepickerMax',
}

/** Match validation error data. */
export type MatchErrorData = {

	/** Control name. */
	readonly controlName: string;

	/** Control title. */
	readonly controlTitle: string;
};

/** Length validation error data. */
export type LengthErrorData = {

	/** Actual length. */
	readonly actualLength: number;

	/** Required length. */
	readonly requiredLength: number;
};

/** Pattern validation error data. */
export type PatternErrorData = {

	/** Actual length. */
	readonly actualValue: string;

	/** Required length. */
	readonly requiredPattern: string;
};

/** App validation error data. */
export type AppErrorData = {

	/** Message. */
	readonly message: string;
};

/** Min value validation error data. */
export type MinValueErrorData = {

	/** Actual value. */
	readonly actual: number;

	/** Min value. */
	readonly min: number;
};

/** Max value validation error data. */
export type MaxValueErrorData = {

	/** Actual value. */
	readonly actual: number;

	/** Min value. */
	readonly max: number;
};

/** Greater value validation error data. */
export type GreaterValueErrorData = {

	/** Control title. */
	readonly controlTitle: string;
};

/** Min datepicker value validation error data. */
export type MinDatePickerValueErrorData = {

	/** Min date. */
	readonly min: Date;
};

/** Max datepicker value validation error data. */
export type MaxDatePickerValueErrorData = {

	/** Max date. */
	readonly max: Date;
};

/** Generic validation error data. */
export type GenericValidationErrorData =
	MatchErrorData &
	LengthErrorData &
	PatternErrorData &
	AppErrorData &
	MinValueErrorData &
	MaxValueErrorData &
	GreaterValueErrorData &
	MinDatePickerValueErrorData &
	MaxDatePickerValueErrorData;
