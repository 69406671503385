import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { QuillModule } from 'ngx-quill';

import { LabelComponent } from './components/label/label.component';
import {
	FormControlValidationMessageComponent,
} from './components/form-control-validation-message/form-control-validation-message.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AppValidationDirective } from './directives/app-validation.directive';
import { MaterialModule } from './material.module';
import { ListDividerPipe } from './pipes/list-divider.pipe';
import { MatrixCellValuePipe } from './pipes/matrix-cell-value.pipe';
import { ResizableColumnComponent } from './components/resizable-column/resizable-column.component';
import { ResizableColumnDirective } from './components/resizable-column/resizable-column.directive';
import { SafePipe } from './pipes/safe.pipe';
import { AutoResizeColumnDirective } from './components/resizable-column/auto-resize-column.directive';

const EXPORTED_DECLARATIONS = [
	AppValidationDirective,
	ValidationMessageComponent,
	FormControlValidationMessageComponent,
	PageLoaderComponent,
	ListDividerPipe,
	LabelComponent,
	MatrixCellValuePipe,
	AutocompleteComponent,
	ResizableColumnComponent,
	ResizableColumnDirective,
	AutoResizeColumnDirective,
	SafePipe,
];

const EXPORTED_MODULES = [
	MaterialModule,
	NgxMatSelectSearchModule,
	QuillModule,
	NgxMaskDirective,
	NgxMaskPipe,
	BreadcrumbComponent,
	BreadcrumbItemDirective,
	InfiniteScrollDirective,
];

/**
 * Common shared module.
 * Contains reusable components, directives and etc can be shared through all apps in the project.
 */
@NgModule({
	declarations: [...EXPORTED_DECLARATIONS],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		...EXPORTED_MODULES,
	],
	exports: [...EXPORTED_DECLARATIONS, ...EXPORTED_MODULES],
	providers: [provideNgxMask()],
})
export class CommonSharedModule {}
