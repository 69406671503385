import { Injectable } from '@angular/core';

import { IMapper } from '@scriptac/common/core/services/mappers/mappers';
import { getDateString, parseDateFromServer } from '@scriptac/common/core/utils/datetime-util';

/** Mapper for dates. */
@Injectable({ providedIn: 'root' })
export class DateMapper implements IMapper<string, Date> {

	/** @inheritdoc */
	public fromDto(dto: string): Date {
		return parseDateFromServer(dto);
	}

	/** @inheritdoc */
	public toDto(data: Date | null): string {
		return data ? getDateString(data) : '';
	}
}
