<label class="label">
	<span
		class="label__content"
		[title]="labelText"
	>
		{{ labelText }}
	</span>
	<div class="label__input">
		<ng-content />
	</div>
	@if (!hideError) {
		<scriptac-validation-message
			class="error"
			[errors]="errors$ | async"
		/>
	}
</label>
