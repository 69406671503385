import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { merge, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { UserProfile } from '../models/user-profile';
import { filterNull } from '../rxjs/filter-null';
import { AccessTierLevel } from '../enums/access-tier-level';
import { AppError } from '../models/app-error';

import { AppConfigService } from './app-config.service';
import { AuthDto } from './mappers/dto/auth-dto';
import { UserProfileDto } from './mappers/dto/user-profile-dto';
import { UserProfileMapper } from './mappers/user-profile-mapper';
import { TokenService } from './token.service';

/** Service for manage current user data. */
@Injectable({ providedIn: 'root' })
export class CurrentUserService {
	private readonly http = inject(HttpClient);

	private readonly config = inject(AppConfigService);

	private readonly userMapper = inject(UserProfileMapper);

	private readonly tokenService = inject(TokenService);

	private readonly profileUrl = new URL('users/users/profile/', this.config.apiUrl).toString();

	private readonly userValue$ = new ReplaySubject<UserProfile | null>(1);

	private readonly userFromStorage$ = this.tokenService.token$.pipe(
		switchMap(token => {
			if (!token) {
				throw new AppError('Unauthorized');
			}
			return this.getCurrentUser();
		}),
		catchError(() => this.logout().pipe(map(() => null))),
	);

	/**
	 * Current user.
	 * Emits `null` if current user is not authenticated.
	 */
	public readonly currentUser$ = merge(this.userFromStorage$, this.userValue$).pipe(shareReplay({ refCount: false, bufferSize: 1 }));

	/** Is current user authenticated. */
	public readonly isAuthenticated$ = this.currentUser$.pipe(
		map(user => user !== null),
		shareReplay({ refCount: false, bufferSize: 1 }),
	);

	/** Whether user has third tier. */
	public readonly hasThirdTier$ = this.currentUser$.pipe(
		map(currentUser => currentUser?.appUserData?.currentAccessTier?.tier === AccessTierLevel.Tier3),
		shareReplay({ refCount: true, bufferSize: 1 }),
	);

	/**
	 * Get current user info.
	 * @returns User.
	 */
	public getCurrentUser(): Observable<UserProfile> {
		return this.http.get<UserProfileDto>(this.profileUrl).pipe(
			filterNull(),
			map(response => this.userMapper.fromDto(response)),
		);
	}

	/** Update user profile. */
	public updateUserData(): Observable<void> {
		return this.getCurrentUser().pipe(
			tap(profile => this.userValue$.next(profile)),
			map(() => undefined),
		);
	}

	/**
	 * Set token and user data.
	 * @param authDto Auth user data.
	 */
	public completeAuthorizationProcess(authDto: AuthDto): Observable<UserProfile> {
		return this.tokenService
			.setToken({
				value: authDto.token,
				expiry: authDto.expiry,
			})
			.pipe(
				map(() => this.userMapper.fromDto(authDto.user)),
				tap(user => this.userValue$.next(user)),
				filterNull(),
			);
	}

	/** Remove the token and clear the current user. */
	public logout(): Observable<void> {
		return this.tokenService.clear().pipe(tap(() => this.userValue$.next(null)));
	}
}
