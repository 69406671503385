import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { StorageService } from './storage.service';

/** Storage key for token. */
const TOKEN_STORAGE_KEY = 'auth_token';

/** TokenData. */
export type TokenData = {

	/** Access token. */
	readonly value: string;

	/** When token should be remove from store. */
	readonly expiry: string;
};

/**
 * Token service.
 * Provides methods to get and set token.
 */
@Injectable({ providedIn: 'root' })
export class TokenService {
	private storageService = inject(StorageService);

	/** Async readonly token value. */
	public readonly token$ = this.storageService
		.get<{ value: string; expiry: string; }>(TOKEN_STORAGE_KEY)
		.pipe(
			map(token => {
				if (token) {
					const date = new Date(token.expiry);
					if (date < new Date()) {
						return null;
					}

					this.tokenValue$.next(token.value);
					return token.value;
				}
				return null;
			}),
		);

	private readonly tokenValue$ = new BehaviorSubject<string>('');

	/**
	 * Set new token value.
	 * @param tokenData Token value and expire.
	 */
	public setToken(tokenData: TokenData): Observable<void> {
		return this.storageService
			.set(TOKEN_STORAGE_KEY, { ...tokenData })
			.pipe(tap(() => this.tokenValue$.next(tokenData.value)));
	}

	/** Get token value synchronously. */
	public getToken(): string {
		return this.tokenValue$.value;
	}

	/** Clear token value. */
	public clear(): Observable<void> {
		return this.storageService
			.set(TOKEN_STORAGE_KEY, '')
			.pipe(tap(() => this.tokenValue$.next('')));
	}
}
