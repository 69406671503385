import { Injectable } from '@angular/core';

/**
 * App config service.
 * Provides information about current application environment configuration.
 */
@Injectable({
	providedIn: 'root',
})
export class AppConfigService {
	/** Is production environment. */
	public readonly isProduction = import.meta.env.NG_APP_PRODUCTION === 'TRUE';

	/** API base URL. */
	public readonly apiUrl = import.meta.env.NG_APP_API_URL;

	/** Scripta URL. */
	public readonly scriptaUrl = import.meta.env.NG_APP_SCRIPTA_URL ?? 'https://scripta.llc';
}
