<div class="wrapper">
	<div class="content">
		<ng-content />
	</div>
	<div
		scriptacResizable
		class="bar"
		(resizable)="onResize($event)"
	></div>
</div>
