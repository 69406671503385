import { formatDate } from '@angular/common';

/** Date format to display on page. */
export const DATE_DISPLAY_FORMAT = 'MM/dd/yyyy';

/**
 * Get ISO date string from date.
 * The output format will be YYYY-MM-DD.
 * @param date Date.
 * @returns Date string.
 */
export function getDateString(date: Date): string {
	return dateToFormattedString(date, 'yyyy-MM-dd');
}

/**
 * Get formatted date string from date.
 * @param date Date.
 * @param format Format to display.
 * @returns Date string.
 */
export function dateToFormattedString(date: Date, format = DATE_DISPLAY_FORMAT): string {
	return formatDate(date, format, 'en');
}

/**
 * Get date with user timezone offset.
 * @param date Date.
 */
export function getDateWithoutTimezoneOffset(date: Date): Date {
	// Get timezone offset in milliseconds.
	const userTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
	return new Date(date.getTime() + userTimezoneOffset);
}

/**
 * Parse date from server.
 * @param dateString Date.
 */
export function parseDateFromServer(dateString: string): Date {
	const date = new Date(dateString);

	if (isInvalidDate(date)) {
		console.warn(`Invalid date string '${dateString}'`);
		return new Date(dateString);
	}

	return getDateWithoutTimezoneOffset(date);
}

/**
 * Add days to date.
 * @param start Start day.
 * @param days Days.
 */
export function addDaysToDate(start: Date, days: number): Date {
	const end = new Date(start);
	end.setDate(start.getDate() + days);
	return end;
}

/**
 * Is date inside range. This check is inclusive.
 * @param start Start date.
 * @param end End date (inclusive).
 * @param date Date to check.
 */
export function isDateInsideRange(start: Date, end: Date, date: Date): boolean {
	return date > start && date <= end;
}

/**
 * Is date invalid.
 * @param date Date to check.
 */
export function isInvalidDate(date?: Date | null): boolean {
	return isNaN(Number(date));
}
