import { enumToArray } from '../utils/enum-to-array';

/**
 * User types.
 */
export enum UserType {

	/** Admin. */
	Admin = 'ADMIN',

	/** User. */
	User = 'APP_USER',
}

export namespace UserType {
	const TITLE_MAP: Record<UserType, string> = {
		[UserType.Admin]: 'Admin',
		[UserType.User]: 'User',
	};

	/**
	 * Convert a certain enum value to readable title.
	 * @param value Value of enum.
	 */
	export function toReadable(value: UserType): string {
		const readable = TITLE_MAP[value];
		if (readable === undefined) {
			console.warn(`Unknown UserType: ${value}`);
		}
		return readable;
	}

	/**
	 * Get list of all enum items.
	 */
	export function toArray(): UserType[] {
		return enumToArray(UserType);
	}
}
