import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Output, inject } from '@angular/core';
import {
	distinctUntilChanged,
	map,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { fromEvent } from 'rxjs';

/**
 * Resizable directive.
 */
@Directive({
	selector: '[scriptacResizable]',
})
export class ResizableColumnDirective {
	private readonly documentRef = inject(DOCUMENT);

	private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

	/** Output. */
	@Output()
	// eslint-disable-next-line rxjs/finnish
	public readonly resizable = fromEvent<MouseEvent>(
			this.elementRef.nativeElement,
			'mousedown',
		).pipe(
			tap(e => e.preventDefault()),
			switchMap(() => {
				const element = this.elementRef.nativeElement.closest('th');
				const clientRect = element?.getBoundingClientRect();

				return fromEvent<MouseEvent>(this.documentRef, 'mousemove').pipe(
					map(({ clientX }) => {
						if (clientRect) {
							const { width, right } = clientRect;
							return width + clientX - right;
						}
						return clientX;
					}),
					distinctUntilChanged(),
					takeUntil(fromEvent(this.documentRef, 'mouseup')),
				);
			}),
		);
}
