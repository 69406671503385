import { MonoTypeOperatorFunction, ObservableInput } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppValidationError } from '../models/app-error';

/**
 * Catch application validation error (instance of AppValidationError) operator.
 * Catches only AppValidationError<T> errors.
 * @param selector Selector.
 */
export function catchValidationError<T, V, TEntity extends object = T extends object ? T : object>(
	selector: (error: AppValidationError<TEntity>) => ObservableInput<V>,
): MonoTypeOperatorFunction<T | V> {
	return catchError((error: unknown) => {
		if (error instanceof AppValidationError) {
			return selector(error);
		}
		throw error;
	});
}
