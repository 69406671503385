import { Injectable, inject } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { AppUser, AppUserEdit } from '../../models/app-user';

import { AccessTierMapper } from './access-tier.mapper';
import { AppUserDto, AppUserEditDto } from './dto/app-user-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/**
 * Mapper for login entities.
 */
@Injectable({ providedIn: 'root' })
export class AppUserMapper
implements IMapperFromDto<AppUserDto, AppUser>, IMapperToDto<AppUserEditDto, AppUserEdit> {
	private readonly accessTierMapper = inject(AccessTierMapper);

	/** @inheritdoc */
	public toDto(data: AppUserEdit): AppUserEditDto {
		return {
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.email,
			phone: data.phone,
			company: data.company ?? null,
			is_active: data.isActive,
			access_tier: data.accessTier ? this.accessTierMapper.toDto(data.accessTier) : null,
		};
	}

	/** @inheritdoc */
	public fromDto(dto: AppUserDto): AppUser {
		return new AppUser({
			id: dto.id,
			firstName: dto.first_name,
			lastName: dto.last_name,
			email: dto.email,
			phone: dto.phone,
			isActive: dto.is_active,
			accessTier: dto.access_tier ? this.accessTierMapper.fromDto(dto.access_tier) : undefined,
			isCompanyOwner: dto.is_company_owner,
			company: dto.company,
			companyData: dto.company_data ?
				{
					id: dto.company_data.id,
					name: dto.company_data.name,
					accessTier: this.accessTierMapper.fromDto(dto.company_data.access_tier),
					owner: dto.company_data.owner,
					city: dto.company_data.city,
					address: dto.company_data.address,
					zipcode: dto.company_data.zipcode,
				} :
				undefined,
			currentAccessTier: dto.current_access_tier_info ?
				this.accessTierMapper.fromDto(dto.current_access_tier_info) :
				undefined,
		});
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<AppUserEditDto>,
	): EntityValidationErrors<AppUserEdit> {
		return {
			firstName: extractErrorMessageByErrorKey(errorDto, 'first_name'),
			lastName: extractErrorMessageByErrorKey(errorDto, 'last_name'),
			email: extractErrorMessageByErrorKey(errorDto, 'email'),
			phone: extractErrorMessageByErrorKey(errorDto, 'phone'),
			accessTier: extractErrorMessageByErrorKey(errorDto, 'access_tier'),
		};
	}
}
