/** Common constants. */

/** Throttle time. */
export const DEFAULT_THROTTLE_TIME = 500;

/** Debounce time. */
export const DEFAULT_DEBOUNCE_TIME = 300;

/** Phone mask. */
export const PHONE_MASK = '(000) 000-0000';

/** Reporting date format. */
export const REPORTING_DATE_FORMAT = 'dd MMM';

/** Quill editor config. */
export const EDITOR_CONFIG = {
	toolbar: [
		['bold', 'italic', 'underline'],
		[{ size: ['small', false, 'large', 'huge'] }],
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ font: [] }],
		['link'],
	],
};

/** Regular expression to find HTML tag. */
export const HTML_TAG_REGEX = /<[^>]*>/g;

/** Identifier of null-object. */
export const NULL_OBJECT_ID = -1;

/** Option ID to select all items. */
export const ALL_OPTION_ID = -1;
