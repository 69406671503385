/**
 * Factory for creation of string from list items property with splitter.
 * @param list List of items to stringify.
 * @param propName Property by which you want to track the item.
 * @param splitter Element to split items in string.
 */
export function stringifyListByProp<T, P extends keyof T = keyof T>(
	list: T[],
	propName: P,
	splitter = ',',
): string {
	return list.map(item => item[propName]).join(splitter);
}
