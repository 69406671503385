import { AccessTier } from './access-tier';
import { Company } from './company';

/** Info for user edit request. */
export type AppUserEdit = Pick<
AppUser,
'firstName' | 'lastName' | 'email' | 'phone' | 'company' | 'accessTier' | 'isActive'
>;

/** User company data. */
export type AppUserCompanyData = Pick<
Company,
'id' | 'name' | 'accessTier' | 'owner' | 'city' | 'address' | 'zipcode'
>;

/** App user model. */
export class AppUser {
	/** User id. */
	public readonly id: number;

	/** User first name. */
	public readonly firstName: string;

	/** User last name. */
	public readonly lastName: string;

	/** User email. */
	public readonly email: string;

	/** User is active. */
	public readonly isActive: boolean;

	/** User phone. */
	public readonly phone: string;

	/** User access tier. */
	public readonly accessTier?: AccessTier;

	/** Is user company owner. */
	public readonly isCompanyOwner: boolean;

	/** User company. */
	public readonly company?: number;

	/** Company data. */
	public readonly companyData?: AppUserCompanyData;

	/** User current access tier. */
	public readonly currentAccessTier?: AccessTier;

	/** Full name of user. */
	public get fullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}

	public constructor(data: AppUserConstructorData) {
		this.id = data.id;
		this.firstName = data.firstName;
		this.lastName = data.lastName;
		this.email = data.email;
		this.phone = data.phone;
		this.accessTier = data.accessTier;
		this.isCompanyOwner = data.isCompanyOwner;
		this.company = data.company;
		this.companyData = data.companyData;
		this.currentAccessTier = data.currentAccessTier;
		this.isActive = data.isActive;
	}
}

type AppUserConstructorData = Omit<AppUser, 'fullName'>;
