import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { AppConfigService } from '@scriptac/common/core/services/app-config.service';

/** Coolie policy dialog component. */
@Component({
	selector: 'scriptaw-cookie-policy-dialog',
	templateUrl: './cookie-policy-dialog.component.html',
	styleUrls: ['./cookie-policy-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiePolicyDialogComponent {

	/** Scripta URL. */
	public readonly scriptaUrl = inject(AppConfigService).scriptaUrl;
}
