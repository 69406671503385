import { Component, ChangeDetectionStrategy, forwardRef, Input, Output, EventEmitter, TrackByFunction, inject, OnInit, DestroyRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NonNullableFormBuilder } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { tap, map, skip } from 'rxjs/operators';

import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import {
	LocationFilterOptions,
} from '@scriptac/common/core/services/location.service';
import { SimpleValueAccessor } from '@scriptac/common/core/utils/value-accessor';

/** Autocomplete component. */
@Component({
	selector: 'scriptac-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AutocompleteComponent),
			multi: true,
		},
	],
})
export class AutocompleteComponent<T, P extends T>
	extends SimpleValueAccessor<T>
	implements OnInit {

	private destroyRef = inject(DestroyRef);

	private readonly fb = inject(NonNullableFormBuilder);

	/** Control for input. */
	public readonly control = this.fb.control<string | T>('');

	/** Input placeholder. */
	@Input()
	public placeholder = 'Start typing...';

	/** Helps to display option at list and in input. */
	@Input()
	public readable: (arg: T) => string = () => '';

	/** Empty value to be selected if value is empty. */
	@Input()
	public emptyValue: P | null = null;

	/** Flag for display loading. */
	@Input()
	public loading: boolean | null = false;

	/** Options list subject. */
	public optionsList$ = new BehaviorSubject<T[] | null>(null);

	/** Options list. */
	@Input()
	public set options(value: T[] | null) {
		if (value) {
			this.optionsList$.next(value);
		}
	}

	/**
	 * Function for trackBy.
	 * @param index Track index.
	 */
	@Input()
	public trackBy: TrackByFunction<T> = (index: number) => index;

	/** Emitter for filters input changed. */
	@Output()
	public filterChanged = new EventEmitter<LocationFilterOptions>();

	/** @inheritdoc */
	public ngOnInit(): void {
		const controlUpdateSideEffect$ = listenControlChanges<string | T>(this.control).pipe(
			skip(1),
			tap(value => {
				if (typeof value !== 'string') {
					this.controlValue = value;
				}
			}),
			map(value => typeof value === 'string' ? value : this.readable(value)),
			tap(v => {
				if (v === '' && this.emptyValue) {
					this.control.setValue(this.emptyValue);
				}
			}),
		);

		controlUpdateSideEffect$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}

	/** @inheritDoc */
	public afterWriteValue(): void {
		if (this.controlValue !== null) {
			this.control.setValue(this.controlValue);
		}
	}
}
