import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for adding divider character between list items.
 * Use it when you need to prevent adding character after last list item.
 * @example
 * ```html
 * <span *ngFor="let value of list; let last = last">
 *  {{ value | listDivider:',':last }}
 * </span>
 * ```
 */
@Pipe({ name: 'listDivider' })
export class ListDividerPipe implements PipeTransform {

	/**
	 * Divide list items by character.
	 *
	 * @param value List item.
	 * @param char Character to divide.
	 * @param isLast Is last item.
	 */
	public transform(value: string, char: string, isLast?: boolean): string {
		return isLast ? value : `${value}${char} `;
	}
}
