import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AppConfigService } from '@scriptac/common/core/services/app-config.service';

/** Privacy policy dialog component. */
@Component({
	selector: 'scriptaw-privacy-policy-dialog',
	templateUrl: './privacy-policy-dialog.component.html',
	styleUrls: ['./privacy-policy-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPolicyDialogComponent {
	private readonly dialogRef = inject<MatDialogRef<PrivacyPolicyDialogComponent>>(MatDialogRef);

	/** Scripta URL. */
	public readonly scriptaUrl = inject(AppConfigService).scriptaUrl;

	/** Closes dialog. */
	public close(): void {
		this.dialogRef.close();
	}
}
