import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

type SimpleType = string | number | boolean | null | undefined;
type SerializableArray = Array<SimpleType | SerializableArray | SerializableObject>;
type SerializableObject = {
	[key: string]: SimpleType | SerializableArray | SerializableObject;
};
type SerializableType = SimpleType | SerializableArray | SerializableObject;

const STORAGE_PREFIX = 'scripta';

/**
 * Service for persistent storing the data.
 */
@Injectable({ providedIn: 'root' })
export class StorageService {
	/**
	 * Read the value from storage by the key.
	 * @param key Key without any prefixes.
	 */
	public get<T extends SerializableType>(key: string): Observable<T | null> {
		return this.getItem(this.generateKey(key));
	}

	/**
	 * Write the value to storage for specific key.
	 * @param key Key without any prefixes.
	 * @param value Json-serializable value.
	 */
	public set<T extends SerializableType>(key: string, value: T): Observable<void> {
		return this.setItem(this.generateKey(key), value);
	}

	/**
	 * Remove storage pair by the key.
	 * @param key Key without any prefixes.
	 */
	public delete(key: string): Observable<void> {
		return this.removeItem(this.generateKey(key));
	}

	/** Remove all data from the storage (not only with current prefix). */
	public clear(): Observable<void> {
		return this.clearStorage();
	}

	/**
	 * Read value from storage.
	 * @param key Key with prefix.
	 */
	private getItem<T extends SerializableType>(key: string): Observable<T | null> {
		return new Observable<T | null>(subscribe => {
			const json = localStorage.getItem(key);
			if (!json) {
				return subscribe.next(null);
			}
			try {
				const value = JSON.parse(json);
				subscribe.next(value);
			} catch (e: unknown) {
				subscribe.next(null);
			}
		});
	}

	/**
	 * Read value from storage.
	 * @param key Key with prefix.
	 * @param value Json-serializable value.
	 */
	private setItem<T extends SerializableType>(key: string, value: T): Observable<void> {
		return new Observable<void>(subscribe => {
			const json = JSON.stringify(value);
			localStorage.setItem(key, json);
			subscribe.next();
		});
	}

	/**
	 * Remove all data from the storage.
	 * @param key Key with prefix.
	 */
	private removeItem(key: string): Observable<void> {
		return new Observable<void>(subscribe => {
			localStorage.removeItem(key);
			subscribe.next();
		});
	}

	/** Remove all data from the storage. */
	private clearStorage(): Observable<void> {
		return new Observable<void>(subscribe => {
			localStorage.clear();
			subscribe.next();
		});
	}

	private generateKey(key: string): string {
		return `${STORAGE_PREFIX}_${key}`;
	}
}
